// QA Form

import React, { useEffect, useMemo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { countryList } from "../utils/utils";
import { getAllFlows, getFlowById, makeCallQA } from "../services/flowService";

const MakeCallForm = ({ backend }) => {
  const [loading, setLoading] = useState();
  const [msg, setMsg] = useState("");
  const [flows, setFlows] = useState([]);

  useEffect(() => {
    // Below function has to be used when we want all the flows
    // in the Django Backend to appear in the make call Form
    const getFlowsFromBackend = async () => {
      const response = await getAllFlows();
      setFlows(response);
    };
    getFlowsFromBackend();
  }, []);

  //Dynamic form values

  const [selectedFlowId, setSelectedFlowId] = useState("");

  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowLoading, setSelectedFlowLoading] = useState(false);

  const handleFlowSelection = async (selected_flow_id) => {
    setSelectedFlow(null);
    setSelectedFlowLoading(true);
    setSelectedFlowId(selected_flow_id);
    try {
      const res = await getFlowById(selected_flow_id);
      if (res?.status === 200) {
        setSelectedFlow(res?.data);
        setSelectedFlowLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  let filteredVariablesArr =
    selectedFlow?.variables?.variables.filter((elem) => elem.value_required) ||
    [];

  let form_variable_fields =
    filteredVariablesArr?.reduce((obj, elem) => {
      return elem.type === "phone"
        ? {
            ...obj,
            [elem.name]: elem.default_value || "",
            [`countryCode_${elem.name}`]: "+91",
          }
        : {
            ...obj,
            [elem.name]: elem.default_value || "",
          };
    }, {}) || {};

  // Yup validation schema

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      Object.keys(form_variable_fields).reduce(
        (obj, elem) => {
          const other_variable = filteredVariablesArr.find(
            (item) => item.name === elem
          );
          if (!elem) {
            return { ...obj };
          } else if (other_variable?.type === "phone") {
            return {
              ...obj,
              [elem]: Yup.string()
                .matches(/^[0-9]{10}$/, `${elem} must be 10 digit`)
                .required(`${elem} is required`),
            };
          } else {
            return {
              ...obj,
              [elem]: Yup.string()
                .matches(/^[^_]*$/, `${elem} cannot contain underscore`)
                .required(`${elem} is required`),
            };
          }
        },
        {
          name: Yup.string()
            .min(1, "Name must be atleast 1 character")
            .matches(/^[^_]*$/, "Name cannot contain underscore")
            .required("Name is required"),
          countryCode: Yup.string().required("Country Code is required"),
          phone: Yup.string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digit")
            .required("Phone number is required"),
        }
      )
    );
  }, [JSON.stringify(form_variable_fields)]);

  return (
    <>
      <div className="my-4 text-base list-none text-black bg-white divide-y divide-gray-100 rounded-md shadow-lg md:w-[30rem] text-left p-4 border border-slate-300">
        <div className="space-y-4">
          {flows.length !== 0 ? (
            <div>
              <label
                htmlFor="flow"
                className="block text-sm font-medium text-gray-700 break-words">
                <span className="text-red-500">*</span>Flow
              </label>
              <select
                name="flow"
                id="flow"
                onChange={(e) => {
                  handleFlowSelection(e.target.value);
                }}
                value={selectedFlowId}
                className={
                  "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md cursor-pointer"
                }>
                <option value="" disabled>
                  Select FLow
                </option>
                {Array.isArray(flows) ? (
                  flows.map((flow, index) => (
                    <option key={flow.id} value={flow.id}>
                      {flow.name}
                    </option>
                  ))
                ) : (
                  <span>No flows available for this url</span>
                )}
              </select>
            </div>
          ) : (
            <div className="text-sm text-center">Loading all flows...</div>
          )}
          {selectedFlowLoading && (
            <div className="text-sm text-center">Loading Flow Variables...</div>
          )}
          {selectedFlowId && selectedFlow && (
            <Formik
              initialValues={{
                name: "Kanik",
                countryCode: "+91",
                phone: "",
                ...form_variable_fields,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setMsg("");
                setLoading(true);

                // Calling QA Backend

                const data = Object.keys(form_variable_fields).reduce(
                  (obj, elem) => {
                    if (!elem) {
                      return obj;
                    }
                    const other_variable = filteredVariablesArr.find(
                      (item) => item.name === elem
                    );
                    if (!other_variable) {
                      return obj;
                    }
                    if (other_variable.type === "phone") {
                      return {
                        ...obj,
                        other_values: {
                          ...obj.other_values,
                          [elem]: `${values[`countryCode_${elem}`]}${
                            values[elem]
                          }`,
                        },
                      };
                    } else {
                      return {
                        ...obj,
                        other_values: {
                          ...obj.other_values,
                          [elem]: values[elem],
                        },
                      };
                    }
                  },
                  {
                    user_name: values.name,
                    user_phone: values.countryCode + values.phone,
                    flow: selectedFlow.backend_json,
                    call_flow_id: selectedFlowId,
                    other_values: {},
                    contact_id: "dummy_qa",
                    campaign_id: "dummy_qa",
                    round_id: "dummy_qa",
                  }
                );
                makeCallQA(data, backend)
                  .then((response) => {
                    setLoading(false);
                    setMsg(response.data?.message);
                  })
                  .catch((err) => {
                    setLoading(false);
                    setMsg("some error occurred");
                  });
              }}>
              {({ errors, touched, values, handleChange, handleBlur }) => {
                return (
                  <Form className="space-y-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700">
                        <span className="text-red-500">*</span>Name
                      </label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                          (errors.name && touched.name ? " border-red-500" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div className="flex w-full gap-3">
                      <div className="w-[50%]">
                        <label
                          htmlFor="countryCode"
                          className="block text-sm font-medium text-gray-700 break-words">
                          <span className="text-red-500">*</span>Phone
                        </label>
                        <Field
                          as="select"
                          name="countryCode"
                          className={
                            "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" +
                            (errors.countryCode && touched.countryCode
                              ? " border-red-500"
                              : "")
                          }>
                          {countryList.map((country, index) => (
                            <option
                              key={country.code}
                              value={country.dial_code}>
                              {`${country.flag} (${country.dial_code})      ${country.code} ${country.name}`}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="countryCode"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>

                      <div className="w-full relative top-3 flex flex-col gap-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700 break-words"></label>
                        <div className="gap-3">
                          <Field
                            name="phone"
                            type="number"
                            className={
                              "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                              (errors.phone && touched.phone
                                ? " border-red-500"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                      </div>
                    </div>

                    {filteredVariablesArr.map((v) => {
                      if (v.type === "string") {
                        return (
                          <div key={v.name}>
                            <label
                              htmlFor={v.name}
                              className="block text-sm font-medium text-gray-700 break-words">
                              <span className="text-red-500">*</span>
                              {v.name}
                            </label>
                            <Field
                              name={v.name}
                              type="text"
                              className={
                                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                                (errors[v.name] && touched[v.name]
                                  ? " border-red-500"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={v.name}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                        );
                      } else if (v.type === "dropdown") {
                        return (
                          <div key={v.name}>
                            <label
                              htmlFor={v.name}
                              className="block text-sm font-medium text-gray-700 break-words">
                              <span className="text-red-500">*</span>
                              {v.name}
                            </label>
                            <Field
                              className={
                                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                                (errors[v.name] && touched[v.name]
                                  ? " border-red-500"
                                  : "")
                              }
                              as="select"
                              name={v.name}>
                              <option value="">Select {v.name}</option>
                              {v.options.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={v.name}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                        );
                      } else if (v.type === "phone") {
                        return (
                          <div key={v.name} className="flex w-full gap-3">
                            <div className="w-[50%]">
                              <label
                                htmlFor={`countryCode_${v.name}`}
                                className="block text-sm font-medium text-gray-700 break-words">
                                <span className="text-red-500">*</span>
                                {v.name}
                              </label>
                              <Field
                                as="select"
                                name={`countryCode_${v.name}`}
                                className={
                                  "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" +
                                  (errors[`countryCode_${v.name}`] &&
                                  touched[`countryCode_${v.name}`]
                                    ? " border-red-500"
                                    : "")
                                }>
                                {countryList.map((country, index) => (
                                  <option
                                    key={country.code}
                                    value={country.dial_code}>
                                    {`${country.flag} (${country.dial_code})      ${country.code} ${country.name}`}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`countryCode_${v.name}`}
                                component="div"
                                className="text-red-500 text-sm mt-1"
                              />
                            </div>

                            <div className="w-full relative top-3 flex flex-col gap-2">
                              <label
                                htmlFor={v.name}
                                className="block text-sm font-medium text-gray-700 break-words"></label>
                              <div className="gap-3">
                                <Field
                                  name={v.name}
                                  type="number"
                                  className={
                                    "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                                    (errors[v.name] && touched[v.name]
                                      ? " border-red-500"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name={v.name}
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      } else if (v.type === "textarea") {
                        return (
                          <div key={v.name}>
                            <label
                              htmlFor={v.name}
                              className="block text-sm font-medium text-gray-700 break-words">
                              <span className="text-red-500">*</span>
                              {v.name}
                            </label>
                            <Field
                              as="textarea"
                              name={v.name}
                              rows={4} // Set the desired number of rows
                              className={
                                "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" +
                                (errors[v.name] && touched[v.name]
                                  ? " border-red-500"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={v.name}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                        );
                      }
                    })}

                    <div className="flex flex-col items-center gap-2 justify-center z-30">
                      <button
                        type="submit"
                        className={`border-[1px] rounded text-white px-5 py-[6px]  ${
                          loading
                            ? "bg-gray-300 text-slate-600"
                            : "bg-black border-black"
                        }`}
                        disabled={loading}>
                        Submit
                      </button>
                      <div>{msg}</div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

export default MakeCallForm;
